/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  app.m16 = function () {
    $('.m16 select').on('change', function (e) {
      $('.m16 form').submit();
    });
  };
})(jQuery, app);
