/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  $(window).on('load', function () {
    app.header();
    app.m01();
    app.m06();
    app.m08();
    app.m15();
    app.m16();
    app.m18();
  });
})(jQuery, app);
