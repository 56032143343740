/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  app.m08 = function () {
    $('.m08__slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.m08__slider__thumbs',
    });
    $('.m08__slider__thumbs').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      infinite: false,
      focusOnSelect: true,
      asNavFor: '.m08__slider',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            dots: false,
            arrows: false,
            centerMode: true,
            centerPadding: '100px',
          },
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
      ],
    });
  };
})(jQuery, app);
