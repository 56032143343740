/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  app.header = function () {
    $('.search-toggle').on('click', function (e) {
      e.preventDefault();
      $('.search_wrapper').toggleClass('is--active');
    });
    $('.hamburger').on('click', function (e) {
      e.preventDefault();
      $(this).toggleClass('toggled');
      $('.nav_wrapper').toggleClass('is--toggled');
    });
  };
})(jQuery, app);
