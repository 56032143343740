/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  app.m06 = function () {
    $('.m06 select').on('change', function (e) {
      $('.m06 form').submit();
    });
  };
})(jQuery, app);
