/* main file */
(function ($, app) {
  // Immediately (before anything goes)
  (function () {})();
  // When everything is rendered
  app.m18 = function () {
    $(document).ready(function () {
      // $('#m18__images').nanogallery2({
      //   thumbnailHeight: 200,
      //   thumbnailWidth: 200,
      // });
    });
  };
})(jQuery, app);
